import Link from 'next/link';

import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  margin-bottom: -4px;
`;

export const WrapperSlide = styled(Link)`
  pointer-events: ${({ href }) => (href ? 'initial' : 'none')};

  > div {
    aspect-ratio: 1280 / 400;
    border-bottom: 1px solid ${(p) => p.theme.colors.border};

    @media (max-width: 500px) {
      aspect-ratio: 500 / 500;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  min-width: 100%;
  object-position: center;
  object-fit: cover;
`;

export const WrapperDots = styled.div`
  bottom: 0;
  background-color: transparent;

  & li {
    margin: 0 ${(p) => p.theme.spacing.small};
  }
`;

export const Dots = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  height: ${(p) => p.theme.spacing.nano};
  width: ${(p) => p.theme.spacing.large};
`;
