/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';

import Slider from 'react-slick';

import { useMediaQuery } from 'hooks/useMediaQuery';

import { IShowcase } from 'types/IShowcase';

import { Wrapper, WrapperSlide, Image, WrapperDots, Dots } from './styles';

interface IRotationBannersProps {
  showcase: IShowcase;
}

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  appendDots: (dots: any) => (
    <WrapperDots>
      <ul> {dots} </ul>
    </WrapperDots>
  ),
  customPaging: () => <Dots />,
};

const RotationBanners: FC<IRotationBannersProps> = ({ showcase }) => {
  const isScreenIn500px = useMediaQuery('(max-width: 500px)');

  const activeRotationBanners =
    !showcase?.rotationBanners || showcase.rotationBanners.length === 0
      ? []
      : showcase.rotationBanners.filter(
          (rotationBanner) => rotationBanner.status === 'active'
        );

  if (!activeRotationBanners || activeRotationBanners.length === 0) return null;

  return (
    <Wrapper>
      <Slider {...settings}>
        {activeRotationBanners.map((banner) => (
          <WrapperSlide key={banner._id} href={banner.url || ''} passHref>
            <div>
              <Image
                alt="Rotation banners"
                src={
                  !!banner.mobilePicture && isScreenIn500px
                    ? banner.mobilePicture
                    : banner.desktopPicture
                }
              />
            </div>
          </WrapperSlide>
        ))}
      </Slider>
    </Wrapper>
  );
};

export default RotationBanners;
